import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ServiceRequestService } from 'gw-capability-servicerequest';
import ImageComponent from '../ImageComponent/ImageComponent';
import styles from './EntryPageTemplate.module.scss';

function EntryPageTemplate(props) {
    const {
        applicationName, applicationLogo, contentToRender, disableOOTBLogin
    } = props;

    useEffect(() => {
        ServiceRequestService.generateSRIHash({})
            .then((data) => {
                const scriptElement = document.getElementById('recaptcha-script');
                if (scriptElement) {
                    scriptElement.setAttribute('integrity', data);
                    // Reload the script by removing and re-adding it
                    const newScript = scriptElement.cloneNode(true);
                    scriptElement.parentNode.replaceChild(newScript, scriptElement);
                }
            }).catch(() => {
                console.log('Error while getting Hash code for recaptcha ');
            });
    }, []);

    return (
        <Fragment>
            <div
                id="entryPageTemplate"
                className={styles.entryPageTemplate}
                style={disableOOTBLogin ? { display: 'none' } : {}}
            >
                <div id="entryPageTemplateHeader" className={styles.entryPageTemplateHeader}>
                    <div id="entryPageTemplateLogo" className={styles.entryPageTemplateLogo}>
                        <ImageComponent src={applicationLogo} title={applicationName} />
                    </div>
                    <h2
                        id="entryPageTemplateApplicationName"
                        className={styles.entryPageTemplateApplicationName}
                    >
                        {applicationName}
                    </h2>
                </div>
                <div id="entryPageTemplateContent" className={styles.entryPageTemplateContent}>
                    <div
                        id="entryPageContentContainer"
                        className={styles.entryPageContentContainer}
                    >
                        {contentToRender}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

EntryPageTemplate.propTypes = {
    applicationName: PropTypes.string.isRequired,
    applicationLogo: PropTypes.string.isRequired,
    contentToRender: PropTypes.shape({}).isRequired
};

export default EntryPageTemplate;
